module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"7","matomoUrl":"https://pagopa.matomo.cloud","matomoJsScript":"matomo.js","matomoPhpScript":"matomo.php","siteUrl":"https://www.pagopa.it"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonNodeName":"allLocale","localeJsonSourceName":"locale","languages":["it"],"fallbackLanguage":"it","generateDefaultLanguagePage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
